/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import history from 'helpers/history';
import { productPropTypes } from 'helpers/propTypes';
import { getFullUrl } from 'helpers/window';
import { sendSuccessNotification, sendErrorNotification } from 'helpers/notifications';
import { updateCart } from 'redux/actions/cart.actions';
import { itemsQuantitySelector, itemsSubQuantitySelector } from 'redux/selectors/cart.selector';
import { itemFavouriteSelector } from 'redux/selectors/favourite.selector';
import { updateFavourite } from 'redux/actions/favourite.actions';
import { productImagesSelector } from 'redux/selectors/product.selector';

import Product from 'views/Product';

const ProductContainer = (props) => {
  const {
    images, item, onClose, updateCartAction, cartItemDescriptions, cartItemSubDescriptions,
    updateFavouritesAction, favouriteItem,
  } = props;
  const singleProductUrl = `/producto/${encodeURIComponent(item.code)}`;
  const singleProductFullUrl = getFullUrl(singleProductUrl);
  const [wholesale, setWholesale] = useState({ id: 0, bool: false, quantity: 0 });
  const [selectedColor, setSelectedColor] = useState(null);
  const [imagesColor, setImagesColor] = useState([]);

  const productCart = cartItemDescriptions.find(
    (data) => ((data.wholesale.bool === wholesale.bool) && (data.wholesale.id === wholesale.id)),
  );
  const productCartSub = cartItemSubDescriptions.find(
    (data) => ((data.wholesale.bool === wholesale.bool) && (data.wholesale.id === wholesale.id)),
  );

  const handleColorSelect = (color) => {
    setSelectedColor(color.stock_description_id);
    const imagesDescription = [];
    if (color.image_1) {
      imagesDescription.push(color.image_1);
    } else {
      imagesDescription.push(images[0]);
    }
    setImagesColor(imagesDescription);
  };

  const colors = item?.stock_product_descriptions?.filter(
    ({ stock_descriptions }) => stock_descriptions.stock_category_id === 1,
  );
  const combinations = colors?.reduce((a, b) => {
    a[b.stock_description_id] = a[b.stock_description_id] || [];
    const combination = item?.stock_product_descriptions
      ?.filter(({ stock_descriptions }) => stock_descriptions.stock_category_id !== 1)
      ?.find(
        (stock_product_description) => stock_product_description.combination === b.combination,
      );
    if (combination) a[b.stock_description_id].push(combination);
    return a;
  }, Object.create(null));

  const onProductClick = () => {
    onClose();
    history.push(singleProductUrl);
  };

  const onProductCopyClick = () => {
    sendSuccessNotification('Link del producto copiado con éxito');
  };

  const onQuantityChange = (value, description) => {
    if (description) {
      updateCartAction(item.id, value, description.id, wholesale);
    } else {
      updateCartAction(item.id, value, null, wholesale);
    }
  };

  const updateFavouriteItems = (id, action) => {
    updateFavouritesAction(id, action);
    if (!action) {
      sendSuccessNotification('Se agregó a favoritos');
    } else {
      sendErrorNotification('Se eliminó de favoritos');
    }
  };

  return (
    <Product
      images={selectedColor ? imagesColor : images}
      selectedColor={selectedColor}
      handleColorSelect={handleColorSelect}
      item={item}
      onProductClick={onProductClick}
      onProductCopyClick={onProductCopyClick}
      onQuantityChange={onQuantityChange}
      productCart={productCart}
      productCartSub={productCartSub}
      cartItemDescriptions={cartItemDescriptions}
      cartItemSubDescriptions={cartItemSubDescriptions}
      singleProductFullUrl={singleProductFullUrl}
      colors={colors}
      combinations={combinations}
      wholesale={wholesale}
      setWholesale={setWholesale}
      updateFavouriteItems={updateFavouriteItems}
      favouriteItem={favouriteItem}
      onClose={onClose}
    />
  );
};

const mapStateToProps = (state, props) => ({
  images: productImagesSelector(state, props),
  cartItemDescriptions: itemsQuantitySelector(state, props),
  cartItemSubDescriptions: itemsSubQuantitySelector(state, props),
  favouriteItem: itemFavouriteSelector(state, props),
});

const mapDispatchToProps = (dispatch) => ({
  updateCartAction: bindActionCreators(updateCart, dispatch),
  updateFavouritesAction: bindActionCreators(updateFavourite, dispatch),
});

ProductContainer.defaultProps = {
  cartItemDescriptions: null,
  cartItemSubDescriptions: null,
  onClose: () => { },
};

ProductContainer.propTypes = {
  cartItemDescriptions: PropTypes.arrayOf(PropTypes.shape({
    descriptionId: PropTypes.number,
  })),
  cartItemSubDescriptions: PropTypes.arrayOf(PropTypes.shape({
    descriptionId: PropTypes.number,
  })),
  item: productPropTypes.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func,
  updateCartAction: PropTypes.func.isRequired,
  updateFavouritesAction: PropTypes.func.isRequired,
  favouriteItem: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
