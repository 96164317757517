import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { productsArrayPropTypes } from 'helpers/propTypes';
import history from 'helpers/history';
import { getSearch } from 'redux/actions/product.actions';
import { openProductModal } from 'redux/actions/modal.actions';

import Modal from 'components/commons/Modal';
import SearchForm from 'components/forms/SearchForm';

const SearchContainer = (props) => {
  const {
    getSearchAction, onClose, openProductModalAction, products,
  } = props;

  const [search, setSearch] = useState('');
  const [loading, setProductsLoading] = useState(null);

  useEffect(() => {
    getSearchAction('');
  }, [getSearchAction]);

  const removeAccents = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  const onChange = ({ search }) => {
    if (search !== undefined){
      getSearchAction(removeAccents(search));
      setSearch(removeAccents(search));
      setProductsLoading(true);
    }
  };

  useEffect(() => {
    if (products.length >= 0) {
      setProductsLoading(false);
    }else{
      setProductsLoading(true);
    }

    // eslint-disable-next-line
  }, [products]);


  const onSubmit = (formData) => {
    onClose();
    const search = removeAccents(formData.search).trim().toLowerCase();
    history.push(`/busqueda/${encodeURIComponent(search)}`);
  };

  return (
    <Modal onClose={onClose}>
      <SearchForm
        items={products}
        onChange={onChange}
        openProductModal={openProductModalAction}
        onSubmit={onSubmit}
        loading={loading}
        search={search}
      />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  products: state.product.search,
});

const mapDispatchToProps = (dispatch) => ({
  getSearchAction: bindActionCreators(getSearch, dispatch),
  openProductModalAction: bindActionCreators(openProductModal, dispatch),
});

SearchContainer.propTypes = {
  getSearchAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  openProductModalAction: PropTypes.func.isRequired,
  products: productsArrayPropTypes.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchContainer);
